







import Vue from "vue";

export default Vue.extend({
  name: "VNoData",
  props: {
    name: String,
  },
});
