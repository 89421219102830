









































































import Vue, { PropType } from "vue";
import moment from "moment";
import { Coupon, UserCoupon } from "@/store";
import { CouponStatus } from "@/enum";

export default Vue.extend({
  name: "VCoupon",
  props: {
    userCoupon: Object as PropType<UserCoupon>,
    selectedId: Number,
    match: Object,
  },
  data() {
    return {
      CouponStatus,
      isCollapsed: true,
    };
  },
  computed: {
    disabled(): boolean {
      return (
        this.matchError ||
        (this.match && !this.match.is_match) ||
        (this.userCoupon.status === "unused" &&
          this.userCoupon.start_at &&
          moment(this.userCoupon.start_at).isAfter(
            moment().add(1, "minutes")
          )) ||
        (this.coupon.coupon_rule.end_at &&
          moment().isAfter(this.coupon.coupon_rule.end_at)) ||
        (this.userCoupon.end_at && moment(this.userCoupon.end_at).isBefore()) ||
        this.userCoupon.status === "locked" ||
        this.userCoupon.status === "used" ||
        this.userCoupon.status === "expired" ||
        this.userCoupon.status === "canceled"
      );
    },
    disabledError(): string {
      if (
        this.userCoupon.status === "unused" &&
        this.userCoupon.start_at &&
        moment(this.userCoupon.start_at).isAfter(moment().add(1, "minutes"))
      ) {
        return "未开始";
      }
      if (
        this.userCoupon.status === "expired" ||
        (this.userCoupon.end_at && moment(this.userCoupon.end_at).isBefore()) ||
        (this.coupon.coupon_rule.end_at &&
          moment().isAfter(this.coupon.coupon_rule.end_at))
      ) {
        return "已过期";
      }
      return "已失效";
    },
    coupon(): Coupon {
      return this.userCoupon.coupon;
    },
    discount(): string {
      const { coupon_code } = this.userCoupon;
      const { category, type, discount, save_amount } = this.coupon.coupon;
      if (category === "special_offer") {
        if (type === "discount") {
          return (discount / 10).toFixed(1) + "折";
        } else {
          return "￥" + save_amount;
        }
      } else if (category === "early_bird") {
        return coupon_code || "必购码";
      }
      return "";
    },
    time(): string {
      if (this.userCoupon.start_at) {
        return (
          "有效期:<span>" +
          moment(this.userCoupon.start_at).format("YYYY-MM-DD") +
          "</span>至<span>" +
          moment(this.userCoupon.end_at).format("YYYY-MM-DD") +
          "</span>"
        );
      } else {
        const {
          validity_date_type,
          validity_days,
          start_at,
          end_at,
        } = this.coupon.coupon_rule;
        if (validity_date_type === "fixed") {
          return (
            "有效期:" +
            moment(start_at).format("YYYY-MM-DD") +
            "至" +
            moment(end_at).format("YYYY-MM-DD")
          );
        } else {
          return "领取后" + validity_days + "天内有效";
        }
      }
    },
    matchError(): string {
      if (!this.match) {
        return "";
      }
      switch (this.match.error_msg) {
        case "less than amount threshold":
          return "未达到满减门槛";
        case "match error":
          return "仅限指定商品可用";
        case "not in validity time":
          return "不在有效期内";
        case "validity type error":
          return "不符合使用条件";
        default:
          return "";
      }
    },
    isMatch(): boolean {
      return !this.matchError && this.match && this.match.is_match;
    },
    toExpir(): boolean {
      return (
        !!this.userCoupon.end_at &&
        moment().add(2, "day").isAfter(this.userCoupon.end_at)
      );
    },
  },
});
