












import Vue from "vue";

export default Vue.extend({
  name: "VProgressBar",
  props: {
    percentage: [Number, String],
    color: String,
  },
});
