import app, { AppState } from "@/store/modules/app";
import profile, { ProfileState } from "@/store/modules/profile";
import settings, { SettingsState } from "@/store/modules/settings";
import files from "@/store/modules/files";
import products, { ProductsState } from "@/store/modules/products";
import cart, { CartState } from "@/store/modules/cart";
import coupons, { CouponsState } from "@/store/modules/coupons";
import addresses, { AddressesState } from "@/store/modules/addresses";
import orders, { OrdersState } from "@/store/modules/orders";
import notifications, {
  NotificationState,
} from "@/store/modules/notifications";
import draw from "@/store/modules/draw";

export interface RootState {
  app: AppState;
  profile: ProfileState;
  settings: SettingsState;
  products: ProductsState;
  cart: CartState;
  coupons: CouponsState;
  addresses: AddressesState;
  orders: OrdersState;
  notifications: NotificationState;
}

export interface ListQuery {
  ordering: string; // 使用 "-"来表示倒序排序，如 -id
  page_number: number;
  page_size: number;
  status?: string; // 状态
  sub_order_status?: string; // 子订单状态
  is_read?: boolean; // 是否未读
  category?: string;
  ids?: string;
}

export default {
  app,
  profile,
  settings,
  files,
  products,
  cart,
  coupons,
  addresses,
  orders,
  notifications,
  draw,
};
