import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery, PendingOrder } from "@/store";
import moment from "moment";

export interface Coupon {
  coupon: {
    amount_threshold: number;
    category: string;
    couponrule_id: number;
    create_time: string;
    description: string;
    discount: number;
    id: number;
    is_active: boolean;
    max_amount_limit: number;
    save_amount: number;
    title: string;
    total_count: number;
    type: string;
    update_time: string;
    uuid: string;
    is_remained?: boolean;
  };
  coupon_rule: {
    create_time: string;
    end_at: string;
    id: number;
    per_claim_number: number;
    start_at: string;
    update_time: string;
    uuid: string;
    validity_date_type: string;
    validity_days: number;
    validity_goods_type: string;
    validity_goods_type_ids: number[];
  };
}

export interface CouponActivity {
  coupon_infos: Coupon[];
  activity_type: string;
  coupon_ids: number[];
  create_time: string;
  custom_id: string;
  description: string;
  end_at: string;
  id: number;
  is_active: boolean;
  start_at: string;
  title: string;
  total_count: number;
  update_time: string;
}

export interface UserCoupon {
  coupon: Coupon;
  amount_threshold: number;
  claim_at: string;
  coupon_activity_code_id: number;
  coupon_code: string;
  coupon_id: number;
  create_time: string;
  discount: number;
  end_at: string;
  id: number;
  max_amount_limit: number;
  order_id: number;
  save_amount: number;
  start_at: string;
  status: string;
  type: string;
  update_time: string;
  use_at: string;
  user_id: 0;
  validity_goods_type: string;
  validity_goods_type_ids: number[];
}

export interface CouponsState {
  list: UserCoupon[];
  query: ListQuery;
  count: number;
}

const state: CouponsState = {
  list: [],
  query: {
    ordering: "-id",
    page_number: 1,
    page_size: 10,
  },
  count: 0,
};

interface Context {
  commit: Commit;
  state: CouponsState;
  rootState: RootState;
}

const actions = {
  async getList(context: Context, query: ListQuery) {
    const res: any = await request({
      url: url.coupon,
      params: query,
    });
    return res;
  },
  bindCode(
    context: Context,
    {
      code,
      category,
    }: {
      code: string;
      category: string;
    }
  ) {
    return request({
      method: "post",
      url: url.couponCodeBind,
      data: { activity_code: code.toUpperCase(), category },
    });
  },
  async getActivity(context: Context, id: string) {
    const res: any = await request({
      url: url.couponActivityX.replace("{id}", id + ""),
    });
    if (res.coupon_ids?.length) {
      const infoRes: any = await request({
        url: url.couponActivityXInfo.replace("{id}", id + ""),
      });
      const couponRes: any = await request({
        url: url.coupon,
        params: {
          page_size: res.coupon_ids.length,
          ids: res.coupon_ids.join(),
        },
      });
      const isActive =
        moment().isAfter(res.start_at) && moment().isBefore(res.end_at);
      res.coupon_infos = couponRes.results.map((item: Coupon) => {
        infoRes.coupon_infos.map((info: any) => {
          if (info.id === item.coupon.id)
            item.coupon.is_remained =
              isActive &&
              info.is_remained &&
              info.user_claimed_number < info.user_max_number;
        });
        return item;
      });
    }
    return res;
  },
  claim(
    context: Context,
    data: {
      coupon_activity_custom_id: string;
      coupon_uuid: string;
    }
  ) {
    return request({
      method: "post",
      url: url.couponActivityClaim,
      data,
    });
  },
  async getUserCouponList({ commit }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.userCoupon,
      params: query,
    });
    if (res.results.length) {
      const couponRes: any = await request({
        url: url.coupon,
        params: {
          page_size: res.results.length,
          ids: res.results.map((item: UserCoupon) => item.coupon_id).join(),
        },
      });
      res.results = res.results.map((item: UserCoupon) => {
        let coupon: Coupon = couponRes.results.find(
          (coupon: Coupon) => coupon.coupon.id === item.coupon_id
        );

        coupon = {
          coupon: {
            ...coupon.coupon,
            amount_threshold: item.amount_threshold,
            discount: item.discount,
            max_amount_limit: item.max_amount_limit,
            save_amount: item.save_amount,
            type: item.type,
          },
          coupon_rule: {
            ...coupon.coupon_rule,
            validity_goods_type: item.validity_goods_type,
            validity_goods_type_ids: item.validity_goods_type_ids,
          },
        };
        return coupon
          ? {
              ...item,
              coupon,
            }
          : item;
      });
    }
    commit(
      "SET_LIST",
      query.page_number === 1 ? res.results : [...state.list, ...res.results]
    );
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size,
    });
    commit("SET_COUNT", res.count);
    return res;
  },
  getUserCoupon(context: Context, id: number) {
    return request({
      url: url.userCouponX.replace("{id}", id + ""),
    });
  },
  async matchUserCoupon(context: Context, order: PendingOrder) {
    const res: any = await request({
      method: "post",
      url: url.userCouponMatch,
      data: {
        products: order.products.map((item) => ({
          number: item.number,
          product_id: item.product_id,
          sku_id: item.sku_id,
        })),
      },
    });
    if (res.results.length) {
      const couponRes: any = await request({
        url: url.coupon,
        params: {
          page_size: res.results.length,
          ids: res.results
            .map((item: any) => item.user_coupon.coupon_id)
            .join(),
        },
      });
      res.results = res.results.map((item: any) => {
        let coupon: Coupon = couponRes.results.find(
          (coupon: Coupon) => coupon.coupon.id === item.user_coupon.coupon_id
        );

        coupon = {
          coupon: {
            ...coupon.coupon,
            amount_threshold: item.user_coupon.amount_threshold,
            discount: item.user_coupon.discount,
            max_amount_limit: item.user_coupon.max_amount_limit,
            save_amount: item.user_coupon.save_amount,
            type: item.user_coupon.type,
          },
          coupon_rule: {
            ...coupon.coupon_rule,
            validity_goods_type: item.user_coupon.validity_goods_type,
            validity_goods_type_ids: item.user_coupon.validity_goods_type_ids,
          },
        };
        return coupon
          ? {
              ...item,
              user_coupon: {
                ...item.user_coupon,
                coupon,
              },
            }
          : item;
      });
    }
    return res;
  },
};

const mutations = {
  SET_LIST: (state: CouponsState, list: UserCoupon[]) => {
    state.list = list;
  },
  SET_QUERY: (state: CouponsState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: CouponsState, count: number) => {
    state.count = count;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
