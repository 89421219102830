import { Commit } from "vuex";
import { RootState } from "@/store/modules";
import { auth } from "@/utils";
import { request, url } from "@/api";

export interface AppState {
  name: string;
  isMobile: boolean;
  isInWeChat: boolean;
  isInQQ: boolean;
  rootPath: string;
}

const toMatch = [
  /Android/i,
  /webOS/i,
  /iPhone/i,
  /iPad/i,
  /iPod/i,
  /BlackBerry/i,
  /Windows Phone/i,
];

const isMobile = toMatch.some((toMatchItem) => {
  return navigator.userAgent.match(toMatchItem);
});

const USERAGENT = navigator.userAgent.toUpperCase();

const state: AppState = {
  name: "Angry Miao",
  isMobile,
  isInWeChat: USERAGENT.includes("MICROMESSENGER"),
  isInQQ: USERAGENT.includes(" QQ") && !USERAGENT.includes("MQQBROWSER"),
  rootPath: "",
};

interface Context {
  commit: Commit;
  state: AppState;
  rootState: RootState;
}

export interface Oauth2 {
  channel: "google" | "discord" | "qq" | "wechat" | "wechat_oa_userinfo";
  action: "callback" | "get_info";
  scope?: "base" | "userinfo";
}

function oauth2Url(oauth2: Oauth2) {
  const { hostname, host, pathname, search } = window.location;
  let url =
    hostname === "localhost"
      ? `https://www.angrymiao.com/`
      : `https://${host}${pathname}${search}`;
  url = url.replace(/code=.*?(&|$)/, "");
  url = url.replace(/channel=.*?(&|$)/, "");
  url = url.replace(/scope=.*?(&|$)/, "");
  url += url.includes("?") ? "&" : "?";
  url += "channel=" + oauth2.channel;
  oauth2.scope && (url += "&scope=" + oauth2.scope);
  return url;
}

const actions = {
  setRootPath({ commit }: Context, path: string) {
    commit("SET_PREV_PATH", path);
  },
  getOauth2Url(context: Context, oauth2: Oauth2) {
    request({
      url: url.authOauth2Url,
      params: {
        ...oauth2,
        redirect_url: oauth2Url(oauth2),
      },
    }).then((res: any) => {
      res.url && (window.location.href = res.url);
    });
  },
  async getWechatOpenid({ rootState }: Context) {
    if (rootState.profile.wechatOpenid) {
      return rootState.profile.wechatOpenid;
    }
    const u = new URL(window.location.href);
    const channel = u.searchParams.get("channel");
    const code = u.searchParams.get("code");
    // alert(channel);
    // alert(code);
    if (channel === "wechat_oa_userinfo" && code) {
      const res: any = await request({
        method: "post",
        url: url.authOauth2Callback,
        data: {
          channel,
          code,
        },
      });
      // alert(JSON.stringify(res));
      if (res.access_info.openid) {
        return res.access_info.openid;
      } else {
        // 没有 openid 时可能是因为没有授权过
        throw new Error(res.access_info.errmsg);
      }
    } else {
      throw new Error("");
    }
  },
  getOauth2Share() {
    return request({
      url: url.authOauth2Share,
      params: {
        url: window.location.href.split("#")[0],
      },
    });
  },
  // async oauth2SignIn() {
  //   const u = new URL(window.location.href);
  //   const action = u.searchParams.get("action");
  //   const channel = u.searchParams.get("channel");
  //   const code = u.searchParams.get("code");
  //   const state = u.searchParams.get("state");
  //   if (action === "callback" && channel && code && state) {
  //     const res: any = await request({
  //       method: "post",
  //       url: url.authOauth2SignIn,
  //       data: {
  //         channel,
  //         code,
  //         state,
  //       },
  //     });
  //     auth.signIn({
  //       token: res.access_token,
  //       refreshToken: res.refresh_token,
  //     });
  //     return true;
  //   } else {
  //     return false;
  //   }
  // },
  // async sendSMS(context: Context, phone: string) {
  //   const res: any = await request({
  //     method: "post",
  //     url: url.authSendSMS,
  //     data: {
  //       phone: phone,
  //     },
  //   });
  //   return res;
  // },
  // async phoneSignIn(
  //   context: Context,
  //   data: {
  //     phone: string;
  //     code: string;
  //   }
  // ) {
  //   const res: any = await request({
  //     method: "post",
  //     url: url.authPhoneSignIn,
  //     data,
  //   });
  //   auth.signIn({
  //     token: res.access_token,
  //     refreshToken: res.refresh_token,
  //   });
  // },
  async refreshToken() {
    const token = auth.getToken();
    const refreshToken = auth.getRefreshToken();
    if (token) {
      return true;
    } else if (refreshToken) {
      window.open(
        url.authRefresh +
          "?redirect=" +
          encodeURIComponent(window.location.href),
        "_self"
      );
      return false;
    } else {
      auth.signOut();
      throw new Error("没有 refresh token");
    }
  },
  signOut() {
    auth.signOut();
    return;
  },
  deregister() {
    return request({
      method: "post",
      url: url.authDeregister,
      data: { is_confirmed: true },
    });
  },
};

const mutations = {
  SET_PREV_PATH: (state: AppState, path: string) => {
    state.rootPath = path;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
