import Vue from "vue";
import Vuex from "vuex";
import modules from "@/store/modules";
import actions from "@/store/actions";

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  modules,
  mutations: {},
});

export { default as dispatch } from "@/store/dispatch";
/* eslint-disable */
export type { RootState, ListQuery } from "@/store/modules";
export type { Oauth2 } from "@/store/modules/app";
export type { ProfileState as Profile } from "@/store/modules/profile";
export type { SettingsState as Settings } from "@/store/modules/settings";
export type { Media } from "@/store/modules/files";
export type { Product, ProductInfo, Attr, Sku } from "@/store/modules/products";
export type { Cart, CartProduct } from "@/store/modules/cart";
export type {
  Coupon,
  CouponActivity,
  UserCoupon,
} from "@/store/modules/coupons";
export type { Address } from "@/store/modules/addresses";
export type {
  Order,
  PendingOrder,
  SubOrder,
  Payment,
  Express,
} from "@/store/modules/orders";
