









import Vue from "vue";

export default Vue.extend({
  name: "VLoading",
  props: {
    loading: Boolean,
  },
});
