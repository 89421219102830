export const ProductStage: {
  conceptual: string;
  available: string;
  pre_sell: string;
  crowdfunding: string;
} = {
  conceptual: "概念中",
  pre_sell: "售卖中",
  available: "现货",
  crowdfunding: "站外发售",
};

export const ProductStatus: {
  pending: string;
  launch: string;
} = {
  pending: "已下架",
  launch: "已上架",
};

export const OrderStatus: {
  unpaid: string;
  paid: string;
  shipping: string;
  confirmed: string;
  closed: string;
  refunding: string;
  refunded: string;
} = {
  unpaid: "待支付",
  paid: "已支付",
  shipping: "运输中",
  confirmed: "已收货",
  closed: "已关闭",
  refunding: "退款中",
  refunded: "已退款",
};

export const CouponStatus: {
  unused: string;
  used: string;
  expired: string;
} = {
  unused: "未使用",
  used: "已使用",
  expired: "已失效",
};
