




















import Vue, { PropType } from "vue";
import { Toast } from "vant";
import Clipboard from "clipboard";

export default Vue.extend({
  name: "VOrderNo",
  data() {
    return {
      visible: false as boolean,
    };
  },
  props: {
    orderNo: String as PropType<string>,
  },
  computed: {
    formatOrderNo(): string {
      if (this.orderNo) {
        return this.visible
          ? this.orderNo
          : this.orderNo.replace(/(.{8}).{12}(.{6})/g, "$1************$2");
      } else {
        return "";
      }
    },
  },
  methods: {
    onClick() {
      const clipboard = new Clipboard(".v-order-no-" + this.orderNo);
      clipboard.on("success", () => {
        Toast("订单号已复制");
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        Toast("订单号复制失败");
        clipboard.destroy();
      });
    },
  },
});
