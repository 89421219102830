import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery } from "@/store";

export interface Address {
  city: string;
  consignee: string;
  country: string;
  create_time: string;
  detail: string;
  district: string;
  is_default: boolean;
  phone: string;
  province: string;
  update_time: string;
  id?: number;
  user_id?: number;
}

export interface AddressesState {
  list: Address[];
  query: ListQuery;
  count: number;
}

const state: AddressesState = {
  list: [],
  query: {
    ordering: "-is_default",
    page_number: 1,
    page_size: 10,
  },
  count: 0,
};

interface Context {
  commit: Commit;
  state: AddressesState;
  rootState: RootState;
}

const actions = {
  async getList({ commit, state }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.address,
      params: query,
    });
    commit(
      "SET_LIST",
      query.page_number === 1 ? res.results : [...state.list, ...res.results]
    );
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size,
    });
    commit("SET_COUNT", res.count);
    return res;
  },
  get(context: Context, id: number) {
    return request({
      url: url.address + "/" + id,
    });
  },
  post(context: Context, address: Address) {
    return request({
      method: "post",
      url: url.address,
      data: address,
    });
  },
  delete(context: Context, address: Address) {
    return request({
      method: "delete",
      url: url.address + "/" + address.id,
    });
  },
  patch(context: Context, address: Address) {
    return request({
      method: "patch",
      url: url.address + "/" + address.id,
      data: address,
    });
  },
};

const mutations = {
  SET_LIST: (state: AddressesState, list: Address[]) => {
    state.list = list;
  },
  SET_QUERY: (state: AddressesState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: AddressesState, count: number) => {
    state.count = count;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
