






































































import Vue, { PropType } from "vue";
import { Product, Sku } from "@/store";
import { ProductStatus } from "@/enum";

export default Vue.extend({
  name: "VProductItem",
  props: {
    product: Object as PropType<Product>,
  },
  data() {
    return {
      ProductStatus,
    };
  },
  computed: {
    sku(): Sku {
      const skus = this.product.skus;
      let sku = skus.find((sku) => sku.has_stock);
      sku = sku || skus[0];
      return sku;
    },
    text(): string {
      const count = this.product.follow_count;
      if (!count) {
        return "";
      } else if (count < 10000) {
        return `${count}人想要`;
      } else {
        return `${(count / 1000).toFixed(1)}k人想要`;
      }
    },
  },
});
