








import Vue from "vue";
import { mapState } from "vuex";
import weChat from "weixin-js-sdk";
import { share } from "@/utils";
import { dispatch } from "@/store";

export default Vue.extend({
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState("app", {
      appName: "name",
      isInWeChat: "isInWeChat",
    }),
    ...mapState("profile", {
      uuid: "uuid",
    }),
  },
  methods: {
    async checkSignIn() {
      try {
        await dispatch.appRefreshToken();
        await dispatch.profileGet().catch(() => {
          dispatch.appSignOut();
        });
        if (this.isInWeChat && this.uuid) {
          dispatch
            .appGetWechatOpenid()
            .then((openid) => {
              console.log("openid", openid);
              dispatch.profileSetOpenid(openid);
              this.loading = false;
            })
            .catch((error) => {
              // alert(window.location.href);
              dispatch.appGetOauth2Url({
                channel: "wechat_oa_userinfo",
                action: "get_info",
                scope: "userinfo",
              });
            });
        } else {
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    setWeChatShare() {
      setTimeout(() => {
        dispatch
          .appGetOauth2Share()
          .then((res: any) => {
            weChat.config({
              debug: false, // 开启调试模式,
              appId: res.app_id, // 必填 appid
              timestamp: res.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.nonce_str, // 必填，生成签名的随机串
              signature: res.signature, // 必填，签名，见附录1
              jsApiList: [
                "updateAppMessageShareData",
                "updateTimelineShareData",
                "onMenuShareAppMessage",
                "onMenuShareTimeline",
                "chooseWXPay",
              ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            });
          })
          .catch((err) => {
            // alert("err" + JSON.stringify(err));
          });
      }, 500);
      share({
        title: "Angry Miao",
        desc: "在 Angry Miao 发掘更多新奇",
        link: window.location.href,
        imgUrl: "https://m.angrymiao.com/logo-medium.png",
      });
    },
    smartScale() {
      const width = document.documentElement.offsetWidth;
      const scaleWidth = width > 992 ? 750 : width;
      document.documentElement.style.fontSize = scaleWidth / 3.75 + "px";
      document.body.style.fontSize = 16 + "px";
      document.body.style.maxWidth = scaleWidth + "px";
    },
  },
  watch: {
    $route(to) {
      document.title = to.name ? to.name : this.appName;
    },
  },
  created() {
    if (this.isInWeChat) {
      this.setWeChatShare();
    }
    this.checkSignIn();
    this.smartScale();
    dispatch.settingsGet();
    dispatch.appSetRootPath(location.pathname + location.search);
    window.addEventListener("resize", this.smartScale);
  },
});
