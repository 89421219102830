import axios, { AxiosRequestConfig } from "axios";
import { Toast } from "vant";
import { auth } from "@/utils";
import { url } from "@/api";

export default ({ headers, ...options }: AxiosRequestConfig) => {
  return new Promise((resolve, reject) => {
    headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      ...headers,
    };

    const token = auth.getToken();
    if (token && !headers["No-Authorization"]) {
      headers["Authorization"] = "Bearer " + token;
    }

    axios({
      ...options,
      timeout: 3600 * 1000,
      headers,
    })
      .then(function (response) {
        switch (response.status) {
          case 200:
          case 201:
          case 203:
          case 204:
            resolve(response.data || true);
            break;
          default:
            reject(response);
            break;
        }
      })
      .catch(function (error) {
        if (error.response) {
          switch (error.response.status) {
            case 401:
              window.open(
                url.authRefresh +
                  "?redirect=" +
                  encodeURIComponent(window.location.href),
                "_self"
              );
          }
          if (error.response.data.message) {
            Toast.clear();
            toastMessage(error.response.data.message);
          }
          reject(error.response.data);
        }
        reject(error);
      });
  });
};

function toastMessage(message: string) {
  const messages: any = {
    "validate code is error or expire": "验证码无效或已过期",
    "greater than limit number": "您已达到购买上限",
    "greater than cart limit": "您已达到加购上限",
    "limit to buy": "您已达到购买上限",
    "out of stock(stock control)": "您选购的商品没有足够的库存",
    "not allow to check this order": "无效订单",
    "fetch product sku error": "商品无效或已被锁定",
    "fetch product error(status error)": "商品还未开售，请耐心等待",
    "invalid product": "请重新选择购物车商品",
    "not whitelisted user":
      "您不在规则名单内，无法购买专属商品。 如需使用“必购码”,请前往个人中心。",
    "not in whitelist":
      "您不在规则名单内，无法购买专属商品。 如需使用“必购码”,请前往个人中心。",
    "in blocked list": "您的账号存在异常，无法购买商品",
    "ent: coupon_activity not found": "活动已关闭或不存在",
    "ent: user_coupon not found": "券已使用或失效",
    "am code coupon fetch error": "必购码无效",
    "coupon out of stock": "您选购的商品没有足够的券码库存",
    "ent: coupon not found": "您选购的商品券码已失效",
    "sku not bound coupon": "您选购的商品没有绑定券码",
    "not generate coupon code": "您选购的商品没有足够的券码库存",
    "sku coupon is not virtual goods": "您选购的商品券码已失效",
    "coupon not enabled": "您选购的商品券码已失效",
    "coupon cannot set public type": "您选购的商品券码已失效",
    "coupon activity have ended": "您选购的商品券码已失效",
    "coupon rule have ended": "您选购的商品券码已失效",
    "am code cannot be used with multiple products":
      "必购码不支持同时下单购买多件商品",
    "authentication credentials were not provided": "",
    "get access token error": "",
    "invalid input": "",
    "user miaocoin are insufficient": "MIAOCOIN 余额不足",
    "the product is not allow to follow": "此商品已暂时关闭想要",
    "20000 - 系统繁忙": "系统繁忙请稍后再试",
    EOF: "浏览器版本不兼容，请使用 Safari 或者微信内置浏览器",
  };
  message = message.replace(" seconds to wait.", " 秒后才能再次发送");
  message = messages[message] || "";

  if (message) {
    Toast({
      message: message,
      duration: 2000,
    });
  }
}
