import Vue from "vue";
import moment from "moment";
import { Product } from "@/store";
import { ProductStage } from "@/enum";

Vue.filter("timeFormat", function (time: string) {
  if (!time) return "";
  return moment(time).format("YYYY/MM/DD HH:mm:ss");
});

Vue.filter("dateFormat", function (time: string) {
  if (!time) return "";
  return moment(time).format("YYYY/MM/DD");
});

Vue.filter("slogan", function (product: Product) {
  const { slogan, stage, sale_at } = product.product;
  const sku = product.skus[0];
  if (slogan) return slogan;
  if (stage === "pre_sell") {
    if (moment(sale_at).isAfter()) {
      return moment(sale_at).format("YYYY年MM月DD日 HH:mm:ss 开始售卖");
    } else if (sku && sku.has_stock) {
      return ProductStage[stage];
    } else {
      return "已售罄";
    }
  } else if (stage === "available") {
    if (sku && sku.has_stock) {
      return ProductStage[stage];
    } else {
      return "已售罄";
    }
  } else {
    return ProductStage[stage];
  }
});
