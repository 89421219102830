import VConsole from "vconsole";

const isTest =
  location.href.includes("test.angrymiao.com") ||
  !location.href.includes(".angrymiao.com");

if (isTest) new VConsole();

const base = `/api`;
const authBase = `https://auth${isTest ? "-test-v2" : ""}.angrymiao.com/api`;
const coinBase = `https://miao-coin${isTest ? "-test" : ""}.angrymiao.com/api`;
const wwwBase = `https://www${isTest ? "-test" : ""}.angrymiao.com`;

export default {
  auth: `https://auth${isTest ? "-test" : ""}.angrymiao.com/`, // 登录页面
  authRefresh: `https://auth${isTest ? "-test" : ""}.angrymiao.com/refresh/`, // 刷新页面

  // authOauth2Url: `${authBase}/passport/oauth2-url`, // 第三方登录链接获取
  // authOauth2SignIn: `${authBase}/passport/oauth2-callback`, // 第三方登录验证
  // authOauth2Config: `${authBase}/passport/oauth2-config`, // 第三方配置信息获取
  // authSendSMS: `${authBase}/passport/sign-in/send-sms`, // 获取短信登录验证码
  // authPhoneSignIn: `${authBase}/passport/sign-in/phone`, // 手机短信登录
  // authRefreshToken: `${authBase}/passport/refresh-token`, // 刷新 token
  authOauth2Url: `${authBase}/oauth2/url`, // 第三方登录链接获取
  authOauth2Callback: `${authBase}/oauth2/callback`, // /oauth2/callback
  authOauth2Share: `${authBase}/share/sign-wechat`, // 第三方分享授权
  authDeregister: `${authBase}/deregister`, // 注销账户
  authProfile: `${authBase}/profile`, // 获取用户profile

  uploadSign: `${base}/upload/sign`, // OSS 登录

  profile: `${base}/profile`, // 获取用户 profile

  product: `${base}/product`, // 获取产品
  hiddenProduct: `${base}/hidden-product`, // 获取隐藏产品
  productFollow: `${base}/follow/product`, // 关注产品
  productShare: `${base}/product-share`, // 分享产品

  cart: `${base}/cart`, // 购物车
  cartCalc: `${base}/cart/calc`, // 购物车下单计算
  cartCheck: `${base}/cart/check`, // 购物车检测
  cartSimplified: `${base}/cart/simplified`, // 购物车摘要

  address: `${base}/address`, // 收货地址

  order: `${base}/order`, // 订单
  subOrder: `${base}/suborder`, // 子订单

  coupon: `${base}/coupon`, // 优惠券
  couponActivityX: `${base}/coupon-activity/x/{id}`, // 活动详情
  couponActivityXInfo: `${base}/coupon-activity/x/{id}/info`, // 活动详情
  couponActivityClaim: `${base}/coupon-activity/claim`, // 活动领取
  couponCodeBind: `${base}/coupon-activity-code/bind`, // 券码兑换
  userCoupon: `${base}/user-coupon`, // 用户优惠券
  userCouponX: `${base}/user-coupon/x/{id}`, // 用户优惠券详情
  userCouponMatch: `${base}/user-coupon/match`, // 用户优惠券使用检查

  payment: `${base}/payment`, // 支付

  notification: `${base}/notification`, // 通知
  notificationMark: `${base}/notification-log/mark`, // 通知已读标记

  settings: `${base}/settings`, // 设置

  expressSupplier: `${base}/express-supplier`, // 快递供应商

  balance: `${coinBase}/balance-count`, // MIAOCOIN 余额

  miaocoin: `${wwwBase}/user-center/?active_tab=miaocoin`, // MIAOCOIN 明细
  AM65LessCustom: `${wwwBase}/am-65-less/custom/`, // AM AFA 定制

  draw: `${base}/draw`, // 登记中奖信息
  drawActivity: `${base}/draw_activity`, // 抽奖活动
  drawShareCode: `${base}/draw_activity/draw_share_code`, // 抽奖活动助力码
  drawAssist: `${base}/draw/draw_activity/assist`, // 抽奖活动助力
};
