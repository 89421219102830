import store, {
  Oauth2,
  Profile,
  SubOrder,
  ListQuery,
  Product,
  CartProduct,
  Address,
  Order,
  PendingOrder,
  Payment,
} from "@/store";

// 应用

const appSetLoading = (loading: boolean) => {
  return store.dispatch("app/setLoading", loading);
};

const appSetRootPath = (path: string) => {
  return store.dispatch("app/setRootPath", path);
};

const appGetOauth2Url = (oauth2: Oauth2) => {
  return store.dispatch("app/getOauth2Url", oauth2);
};

const appGetWechatOpenid = () => {
  return store.dispatch("app/getWechatOpenid");
};

const appGetOauth2Share = () => {
  return store.dispatch("app/getOauth2Share");
};

const appOauth2SignIn = () => {
  return store.dispatch("app/oauth2SignIn");
};

const appSendSMS = (phone: string) => {
  return store.dispatch("app/sendSMS", phone);
};

const appPhoneSignIn = (data: { phone: string; code: string }) => {
  return store.dispatch("app/phoneSignIn", data);
};

const appRefreshToken = () => {
  return store.dispatch("app/refreshToken");
};

const appSignOut = () => {
  return store.dispatch("app/signOut");
};

const appDeregister = () => {
  return store.dispatch("app/deregister");
};

// 个人信息

const profileGet = () => {
  return store.dispatch("profile/get");
};

const profilePatch = (profile: Profile) => {
  return store.dispatch("profile/patch", profile);
};

const profileSetOpenid = (openid: string) => {
  return store.dispatch("profile/setOpenid", openid);
};

const profileGetBalance = () => {
  return store.dispatch("profile/getBalance");
};

// 系统设置

const settingsGet = () => {
  return store.dispatch("settings/get");
};

// 文件上传

const filesSign = () => {
  return store.dispatch("files/sign");
};

const filesUpload = (file: File, callback?: (e: ProgressEvent) => void) => {
  return store.dispatch("files/upload", {
    file,
    callback,
  });
};

// 产品

const productsGetList = (query: ListQuery) => {
  return store.dispatch("products/getList", query);
};

const productsGetTopList = () => {
  return store.dispatch("products/getTopList");
};

const productsGet = (id: string, shareId?: string) => {
  return store.dispatch("products/get", { id, shareId });
};

const productsGetHidden = (id: string, shareId?: string) => {
  return store.dispatch("products/getHidden", { id, shareId });
};

const productsFollow = (product: Product) => {
  return store.dispatch("products/follow", product);
};

const productsShare = (id: string) => {
  return store.dispatch("products/share", id);
};

// 购物车

const cartGetList = (query: ListQuery) => {
  return store.dispatch("cart/getList", query);
};

const cartPost = (product: CartProduct) => {
  return store.dispatch("cart/post", product);
};

const cartPatch = (productList: CartProduct[]) => {
  return store.dispatch("cart/patch", productList);
};

const cartDelete = (productList: CartProduct[]) => {
  return store.dispatch("cart/delete", productList);
};

const cartCheck = (productList: CartProduct[]) => {
  return store.dispatch("cart/check", productList);
};

const cartCalc = (data: {
  user_coupon_id: number;
  products: CartProduct[];
}) => {
  return store.dispatch("cart/calc", data);
};

// 优惠券

const couponsGetList = (query: ListQuery) => {
  return store.dispatch("coupons/getList", query);
};

const couponsBindCode = ({
  code,
  category,
}: {
  code: string;
  category: string;
}) => {
  return store.dispatch("coupons/bindCode", { code, category });
};

const couponsGetActivity = (id: string) => {
  return store.dispatch("coupons/getActivity", id);
};

const couponsClaim = (data: {
  coupon_activity_custom_id: string;
  coupon_uuid: string;
}) => {
  return store.dispatch("coupons/claim", data);
};

const couponsGetUserCouponList = (query: ListQuery) => {
  return store.dispatch("coupons/getUserCouponList", query);
};

const couponsGetUserCoupon = (id: number) => {
  return store.dispatch("coupons/getUserCoupon", id);
};

const couponsMatchUserCoupon = (order: PendingOrder) => {
  return store.dispatch("coupons/matchUserCoupon", order);
};

// 收货地址

const addressesGetList = (query: ListQuery) => {
  return store.dispatch("addresses/getList", query);
};

const addressesGet = (id: string) => {
  return store.dispatch("addresses/get", id);
};

const addressesPost = (address: Address) => {
  return store.dispatch("addresses/post", address);
};

const addressesPatch = (address: Address) => {
  return store.dispatch("addresses/patch", address);
};

const addressesDelete = (address: Address) => {
  return store.dispatch("addresses/delete", address);
};

// 订单

const ordersGetList = (query: ListQuery) => {
  return store.dispatch("orders/getList", query);
};

const ordersGet = (orderNo: string) => {
  return store.dispatch("orders/get", orderNo);
};

const ordersSetPendingOrder = (order: PendingOrder) => {
  return store.dispatch("orders/setPendingOrder", order);
};

const ordersPost = (order: PendingOrder) => {
  return store.dispatch("orders/post", order);
};

const ordersPayment = (payment: Payment) => {
  return store.dispatch("orders/payment", payment);
};

const ordersAddressPost = ({
  orderNo,
  address,
}: {
  orderNo: string;
  address: Address;
}) => {
  return store.dispatch("orders/addressPost", { orderNo, address });
};

const ordersStatusPatch = (order: Order) => {
  return store.dispatch("orders/statusPatch", order);
};

const ordersSubStatusPatch = (subOrder: SubOrder) => {
  return store.dispatch("orders/subStatusPatch", subOrder);
};

// 公告

const notificationsGetList = (query: ListQuery) => {
  return store.dispatch("notifications/getList", query);
};

const notificationsGetUnRead = () => {
  return store.dispatch("notifications/getUnRead");
};

const notificationsGet = (id: string) => {
  return store.dispatch("notifications/get", id);
};

const notificationsMark = (id?: string) => {
  return store.dispatch("notifications/mark", id);
};

// 抽奖
const drawGetActivity = (code: string) => {
  return store.dispatch("draw/getActivity", code);
};

export default {
  appSetLoading,
  appSetRootPath,
  appGetOauth2Url,
  appGetWechatOpenid,
  appGetOauth2Share,
  appOauth2SignIn,
  appSendSMS,
  appPhoneSignIn,
  appRefreshToken,
  appSignOut,
  appDeregister,

  profileGet,
  profilePatch,
  profileSetOpenid,
  profileGetBalance,

  settingsGet,

  filesSign,
  filesUpload,

  productsGetList,
  productsGetTopList,
  productsGet,
  productsGetHidden,
  productsFollow,
  productsShare,

  cartGetList,
  cartPost,
  cartPatch,
  cartDelete,
  cartCheck,
  cartCalc,

  couponsGetList,
  couponsBindCode,
  couponsGetActivity,
  couponsClaim,
  couponsGetUserCouponList,
  couponsGetUserCoupon,
  couponsMatchUserCoupon,

  addressesGetList,
  addressesGet,
  addressesPost,
  addressesPatch,
  addressesDelete,

  ordersGetList,
  ordersGet,
  ordersSetPendingOrder,
  ordersPost,
  ordersPayment,
  ordersAddressPost,
  ordersStatusPatch,
  ordersSubStatusPatch,

  notificationsGetList,
  notificationsGetUnRead,
  notificationsGet,
  notificationsMark,

  drawGetActivity,
};
