import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery } from "@/store";

export interface Notification {
  id: number;
  user_id: number;
  content: string;
  create_time: string;
  is_active: boolean;
  publish_at: string;
  title: string;
  type: string;
  update_time: string;
}

export interface NotificationState {
  list: Notification[];
  query: ListQuery;
  count: number;
}

const state: NotificationState = {
  list: [],
  query: {
    ordering: "",
    page_number: 1,
    page_size: 10,
  },
  count: 0,
};

interface Context {
  commit: Commit;
  state: NotificationState;
  rootState: RootState;
}

const actions = {
  async getList({ commit }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.notification,
      params: query,
    });
    res.results = res.results || [];
    commit(
      "SET_LIST",
      query.page_number === 1 ? res.results : [...state.list, ...res.results]
    );
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size,
    });
    commit("SET_COUNT", res.count);
    return res;
  },
  getUnRead() {
    return request({
      url: url.notification,
      params: {
        page_size: 1,
        page_number: 1,
        ordering: "",
        is_read: false,
      },
    });
  },
  get(context: Context, id: string) {
    return request({
      url: url.notification + "/" + id,
    });
  },
  mark(context: Context, id?: string) {
    const data = id ? { notification_ids: [Number(id)] } : { is_all: true };
    return request({
      url: url.notificationMark,
      method: "post",
      data,
    });
  },
};

const mutations = {
  SET_LIST: (state: NotificationState, list: Notification[]) => {
    state.list = list;
  },
  SET_QUERY: (state: NotificationState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: NotificationState, count: number) => {
    state.count = count;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
