
















import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";

export default Vue.extend({
  name: "VHeader",
  data() {
    return {
      timer: 0,
    };
  },
  computed: {
    ...mapState("app", {
      rootPath: "rootPath",
    }),
  },
  methods: {
    onBack() {
      if (this.rootPath === this.$route.fullPath) {
        this.$router.push(this.$paths.root);
        dispatch.appSetRootPath(this.$paths.root);
      } else {
        this.$router.back();
      }
    },
    onLogoClick() {
      window.location.href = "https://www.angrymiao.com/";
    },
  },
});
