import dispatch from "@/store/dispatch";
import { RootState } from "@/store/modules";

interface C {
  rootState: RootState;
}

export default {
  example(c: C, loading: boolean) {
    dispatch.appSetLoading(loading);
  },
};
