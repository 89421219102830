import { Commit } from "vuex";
import { Toast } from "vant";
import { RootState } from "@/store/modules";
import { request, url } from "@/api";
import { paths } from "@/router";
import { auth } from "@/utils";

export interface ProfileState {
  uuid: string;
  id: string;
  avatar: string;
  nickname: string;
  phone: string;
  role: string;
  wechatOpenid: string;
}

const state: ProfileState = {
  uuid: "",
  id: "",
  avatar: "",
  nickname: "",
  phone: "",
  role: "",
  wechatOpenid: "",
};

interface Context {
  commit: Commit;
  state: ProfileState;
  rootState: RootState;
}

const actions = {
  async get({ commit, state }: Context) {
    const res: any = await request({
      url: url.profile,
    });
    const authRes: any = await request({
      url: url.authProfile,
    });
    commit("SET_PROFILE", {
      ...state,
      ...res,
      ...authRes,
    });
    if (authRes.is_active === false) {
      Toast("账户已注销");
      auth.signOut();
      window.location.href = paths.authSignIn;
    }
    return;
  },
  patch({ commit, state }: Context, profile: ProfileState) {
    commit("SET_PROFILE", {
      ...state,
      ...profile,
    });
    return request({
      method: "patch",
      url: url.profile,
      data: profile,
    });
  },
  setOpenid({ commit, state }: Context, openid: string) {
    commit("SET_PROFILE", {
      ...state,
      wechatOpenid: openid,
    });
  },
  getBalance() {
    return request({
      url: url.balance,
    });
  },
};

const mutations = {
  SET_PROFILE: (state: ProfileState, profile: ProfileState) => {
    state.uuid = profile.uuid;
    state.id = profile.id;
    state.avatar = profile.avatar;
    state.nickname = profile.nickname;
    state.phone = profile.phone;
    state.role = profile.role;
    state.wechatOpenid = profile.wechatOpenid;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
