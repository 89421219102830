import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery } from "@/store";

export interface Attr {
  name: string;
  priority: number;
  values: string[];
  id?: number;
}

export interface ProductInfo {
  create_time: string;
  deliver_at: string;
  description: string;
  detail_medias: any[];
  is_top: boolean;
  is_hidden: boolean;
  hidden_id: string;
  is_share?: boolean;
  main_medias: any[];
  name: string;
  slogan: string;
  priority: number;
  qr_code_images: any[];
  sale_at: string;
  stage: "conceptual" | "available" | "pre_sell" | "crowdfunding";
  status: string;
  update_time: string;
  url: string;
  id: number;
  is_virtual: boolean;
  is_coin_pay: boolean;
}

export interface Sku {
  amount: number;
  coin: number;
  extra_amount: number;
  limit: number;
  name: string;
  options: any[];
  medias: any[];
  priority: number;
  stock: number;
  id: number;
  has_stock?: boolean;
  is_active?: boolean;
  is_overdue?: boolean;
}

interface StockPolicy {
  id: number;
  max_stock: number;
  sku_ids: number[];
}

export interface Product {
  attrs: Attr[];
  follow_count: number;
  following: boolean;
  sell_percent: number;
  product: ProductInfo;
  skus: Sku[];
  stock_policies: StockPolicy[];
}

export interface ProductsState {
  list: Product[];
  query: ListQuery;
  count: number;
}

const state: ProductsState = {
  list: [],
  query: {
    ordering: "-priority",
    page_number: 1,
    page_size: 10,
  },
  count: 0,
};

interface Context {
  commit: Commit;
  state: ProductsState;
  rootState: RootState;
}

const actions = {
  async getList({ commit }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.product,
      params: query,
    });
    commit(
      "SET_LIST",
      query.page_number === 1 ? res.results : [...state.list, ...res.results]
    );
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size,
    });
    commit("SET_COUNT", res.count);
    return res;
  },
  async getTopList() {
    const res: any = await request({
      url: url.product,
      params: {
        ordering: "-is_top",
        page_number: 1,
        page_size: 10,
      },
    });
    const list = res.results.filter((item: Product) => item.product.is_top);
    return list;
  },
  get(context: Context, { id, shareId }: { id: string; shareId?: string }) {
    let u = url.product + "/" + id;
    if (shareId) u += "/" + shareId;
    return request({ url: u });
  },
  getHidden(
    context: Context,
    { id, shareId }: { id: string; shareId?: string }
  ) {
    let u = url.hiddenProduct + "/" + id;
    if (shareId) u += "/" + shareId;
    return request({ url: u });
  },
  follow(context: Context, product: Product) {
    return request({
      method: "post",
      url: url.productFollow,
      data: {
        is_active: !product.following,
        product_id: product.product.id,
      },
    });
  },
  patch(context: Context, product: Product) {
    return request({
      method: "patch",
      url: url.product + "/" + product.product.id,
      data: product,
    });
  },
  delete(context: Context, product: Product) {
    return request({
      method: "delete",
      url: url.product + "/" + product.product.id,
    });
  },
  share(context: Context, id: string) {
    return request({
      url: url.productShare + "/" + id,
    });
  },
};

const mutations = {
  SET_LIST: (state: ProductsState, list: Product[]) => {
    state.list = list;
  },
  SET_QUERY: (state: ProductsState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: ProductsState, count: number) => {
    state.count = count;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
