import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState } from "@/store/modules";

export interface ExpressSupplier {
  code: string;
  name: string;
}

export interface SettingsState {
  shipping: {
    amount: number;
    is_open: boolean;
    threshold: number;
  };
  refund: {
    is_open: boolean;
  };
  expressSupplier: ExpressSupplier[];
  address: {
    edit_addr: boolean;
  };
}

const state: SettingsState = {
  shipping: {
    amount: 0,
    is_open: true,
    threshold: 0,
  },
  refund: {
    is_open: true,
  },
  expressSupplier: [],
  address: {
    edit_addr: false,
  },
};

export interface Media {
  name: string;
  type: string;
  url: string;
}

interface Context {
  commit: Commit;
  rootState: RootState;
}

const actions = {
  async get({ commit }: Context) {
    const settings: any = await request({
      url: url.settings,
    });
    commit("SET_SETTINGS", settings);
    const expressSupplier: any = await request({
      url: url.expressSupplier,
      params: { page_size: 1000 },
    });
    commit("SET_EXPRESS_SUPPLIER", expressSupplier.results);
    return settings;
  },
};

const mutations = {
  SET_SETTINGS: (state: SettingsState, settings: SettingsState) => {
    state.shipping = settings.shipping;
    state.refund = settings.refund;
    state.address = settings.address;
  },
  SET_EXPRESS_SUPPLIER: (
    state: SettingsState,
    expressSupplier: ExpressSupplier[]
  ) => {
    state.expressSupplier = [
      { name: "顺丰快递", code: "SF" },
      ...expressSupplier,
    ];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
