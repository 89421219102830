import Vue from "vue";
import VueRouter, { RouteConfig, RawLocation } from "vue-router";

const publicPath = "";

export declare interface Paths {
  root: string;
  auth: string;
  authSignIn: string;
  authSigned: string;
  userRule: string;
  privacyRule: string;
  home: string;
  profile: string;
  products: string;
  productsDetail: string;
  productsHidden: string;
  productsHiddenDetail: string;
  cart: string;
  placeOrder: string;
  couponSelect: string;
  coupons: string;
  couponActivity: string;
  addresses: string;
  addressesAdd: string;
  addressesEdit: string;
  orders: string;
  ordersDetail: string;
  notifications: string;
  notificationsDetail: string;
  drawActivity: string;
}

export const paths: Paths = {
  root: publicPath + "/",
  auth: publicPath + "/auth",
  authSignIn: publicPath + "/auth/sign-in",
  authSigned: publicPath + "/auth/signed",
  userRule: publicPath + "/user-rule",
  home: publicPath + "/home/",
  profile: publicPath + "/profile",
  privacyRule: publicPath + "/privacy-rule",
  products: publicPath + "/products",
  productsDetail: publicPath + "/products/:id/:shareId?",
  productsHidden: publicPath + "/products/hidden",
  productsHiddenDetail: publicPath + "/products/hidden/:id/:shareId?",
  cart: publicPath + "/cart",
  placeOrder: publicPath + "/place-order",
  couponSelect: publicPath + "/coupon-select",
  coupons: publicPath + "/coupons",
  couponActivity: publicPath + "/coupon-activity/:id",
  addresses: publicPath + "/addresses",
  addressesAdd: publicPath + "/addresses/add",
  addressesEdit: publicPath + "/addresses/:id",
  orders: publicPath + "/orders",
  ordersDetail: publicPath + "/orders/:id",
  notifications: publicPath + "/notifications",
  notificationsDetail: publicPath + "/notifications/:id",
  drawActivity: publicPath + "/draw-activity/:code",
};

const routes: Array<RouteConfig> = [
  {
    path: paths.auth,
    redirect: paths.authSignIn,
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        name: "登录",
        path: paths.authSignIn,
        component: () => import("@/views/SignIn.vue"),
      },
      {
        name: "一键登录",
        path: paths.authSigned,
        component: () => import("@/views/Signed.vue"),
      },
    ],
  },
  {
    name: "用户协议",
    path: paths.userRule,
    component: () => import("@/views/UserRule.vue"),
  },
  {
    name: "隐私协议",
    path: paths.privacyRule,
    component: () => import("@/views/PrivacyRule.vue"),
  },
  {
    name: "Angry Miao",
    path: paths.home,
    component: () => import("@/views/Home.vue"),
  },
  {
    path: paths.root,
    redirect: paths.home,
    component: () => import("@/layouts/MainLayout.vue"),
    children: [
      {
        path: paths.profile,
        name: "个人中心",
        component: () => import("@/views/Profile.vue"),
      },
      {
        path: paths.productsHiddenDetail,
        name: "Angry Miao",
        component: () => import("@/views/ProductDetail.vue"),
        props: { hidden: true },
      },
      {
        path: paths.productsDetail,
        name: "Angry Miao",
        component: () => import("@/views/ProductDetail.vue"),
      },
      {
        path: paths.cart,
        name: "购物车",
        component: () => import("@/views/Cart.vue"),
      },
      {
        path: paths.placeOrder,
        name: "确认订单",
        component: () => import("@/views/PlaceOrder.vue"),
      },
      {
        path: paths.couponSelect,
        component: () => import("@/views/CouponSelect.vue"),
      },
      {
        path: paths.coupons + "/:type?",
        name: "我的优惠券",
        component: () => import("@/views/CouponList.vue"),
      },
      {
        path: paths.couponActivity,
        name: "活动",
        component: () => import("@/views/CouponActivity.vue"),
      },
      {
        path: paths.products,
        name: "兑换使用选择",
        component: () => import("@/views/ProductList.vue"),
      },
      {
        path: paths.addresses,
        name: "收货地址",
        component: () => import("@/views/AddressList.vue"),
      },
      {
        path: paths.addressesAdd,
        name: "添加地址",
        component: () => import("@/views/AddressDetail.vue"),
      },
      {
        path: paths.addressesEdit,
        name: "修改地址",
        component: () => import("@/views/AddressDetail.vue"),
      },
      {
        path: paths.orders,
        name: "我的订单",
        component: () => import("@/views/OrderList.vue"),
      },
      {
        path: paths.ordersDetail,
        name: "订单详情",
        component: () => import("@/views/OrderDetail.vue"),
      },
      {
        path: paths.notifications,
        name: "公告消息",
        component: () => import("@/views/NotificationList.vue"),
      },
      {
        path: paths.notificationsDetail,
        name: "公告详情",
        component: () => import("@/views/NotificationDetail.vue"),
      },
      {
        path: paths.drawActivity,
        name: "幸运抽奖",
        component: () => import("@/views/DrawActivity.vue"),
      },
    ],
  },
  {
    path: "*",
    redirect: paths.home,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.path !== from.path) {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

const VueRouterPush = VueRouter.prototype.push;
const VueRouterReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location: RawLocation) {
  return (VueRouterPush.call(this, location) as any).catch((err: Error) => err);
};
VueRouter.prototype.replace = function replace(location: RawLocation) {
  return (VueRouterReplace.call(this, location) as any).catch(
    (err: Error) => err
  );
};

Vue.use(VueRouter);
Vue.prototype.$paths = paths;

export default router;
