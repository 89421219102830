









































import Vue from "vue";
import { mapState } from "vuex";
import QRCode from "qrcode";
import { dispatch, Product, Media } from "@/store";
import H2C from "html2canvas";
import { Notify } from "vant";
import "canvas2image";

export default Vue.extend({
  name: "VProductShare",
  data() {
    return {
      loading: true,
      visible: false,
      image: "",
      name: "",
      slogan: "",
      price: 0,
      code: "",
      shareImage: "",
    };
  },
  computed: {
    ...mapState("profile", {
      uuid: "uuid",
    }),
    productId(): string {
      const { id } = this.$route.params;
      return id;
    },
  },
  methods: {
    getProduct() {
      dispatch.productsGet(this.productId).then((product: Product) => {
        const media: Media = product.product.main_medias.find(
          (item: Media) => item.type === "image"
        );
        const img = new Image();
        img.src = media?.url;
        this.image = media?.url;
        this.name = product.product.name;
        this.slogan = product.product.slogan;
        this.price = 0;
        product.skus.map((item) => {
          if (this.price === 0 || item.amount < this.price)
            this.price = item.amount;
        });
        if (product.product.is_share && !product.product.is_hidden)
          this.loading = false;
      });
    },
    onShare() {
      if (this.uuid) {
        this.visible = true;
        dispatch.productsShare(this.productId).then((res) => {
          QRCode.toDataURL(
            location.origin + location.pathname + "/" + res.share_code,
            { margin: 0 }
          ).then((url: string) => {
            this.code = url;
            setTimeout(() => {
              H2C(document.querySelector("#share-box") as HTMLElement, {
                useCORS: true,
                backgroundColor: null,
              }).then((canvas) => {
                const context = canvas.getContext("2d");
                if (context) {
                  canvas.toDataURL();
                  context.imageSmoothingEnabled = false;
                  this.shareImage = canvas.toDataURL();
                  Notify("长按图片保存或截屏分享");
                }
              });
            }, 1000);
          });
        });
      } else {
        this.$router.push(this.$paths.authSignIn);
      }
    },
    onDownload() {
      H2C(document.querySelector("#share-box") as HTMLElement, {
        useCORS: true,
        backgroundColor: null,
      }).then((canvas) => {
        const context = canvas.getContext("2d");
        if (context) {
          canvas.toDataURL();
          context.imageSmoothingEnabled = false;
          const link = document.createElement("a");
          link.href = canvas.toDataURL();
          link.download = this.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    },
  },
  mounted() {
    if (this.productId) {
      this.getProduct();
    }
  },
});
