import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery, ProductInfo, Sku } from "@/store";

export interface Cart {
  number: number;
  product: ProductInfo;
  sku: Sku;
  select: boolean;
}

export interface CartState {
  list: Cart[];
  query: ListQuery;
  count: number;
}

const state: CartState = {
  list: [],
  query: {
    ordering: "-created",
    page_number: 1,
    page_size: 1000,
  },
  count: 0,
};

interface Context {
  commit: Commit;
  state: CartState;
  rootState: RootState;
}

export interface CartProduct {
  number: number;
  product_id: number;
  sku_id: number;
  share_code?: string;
}

const actions = {
  async getList({ commit }: Context, query: ListQuery) {
    const res: any = await request({
      url: url.cart,
      params: query,
    });
    res.results = (res.results || []).filter(
      (item: Cart) => item.product.id && item.sku.id
    );
    commit("SET_LIST", res.results);
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size,
    });
    commit("SET_COUNT", res.results.length);
    return res;
  },
  post(context: Context, product: CartProduct) {
    return request({
      method: "post",
      url: url.cart,
      data: product,
    });
  },
  patch(context: Context, productList: CartProduct[]) {
    return request({
      method: "patch",
      url: url.cart,
      data: productList,
    });
  },
  delete(context: Context, productList: CartProduct[]) {
    return request({
      method: "delete",
      url: url.cart,
      data: productList,
    });
  },
  check(context: Context, productList: CartProduct[]) {
    return request({
      method: "post",
      url: url.cartCheck,
      data: productList,
    });
  },
  calc(
    context: Context,
    data: {
      couponID: number;
      products: CartProduct[];
    }
  ) {
    return request({
      method: "post",
      url: url.cartCalc,
      data,
    });
  },
};

const mutations = {
  SET_LIST: (state: CartState, list: Cart[]) => {
    state.list = list;
  },
  SET_QUERY: (state: CartState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: CartState, count: number) => {
    state.count = count;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
