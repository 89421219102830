import { Commit } from "vuex";
import { request, url } from "@/api";
import { RootState, ListQuery, CartProduct, Address } from "@/store";

export interface Express {
  address_info: any;
  create_time: string;
  express_no: string;
  id: number;
  sub_order_id: number;
  supplier: string;
  update_time: string;
  code?: string;
}

export interface SubOrder {
  address_info: any;
  amount: number;
  create_time: string;
  express: Express;
  extra_amount: number;
  has_express: boolean;
  id: number;
  number: number;
  order_id: number;
  phone: string;
  product_id: number;
  product_snapshot: any;
  sku_id: number;
  status: string;
  sub_order_no: string;
  update_time: string;
}

export interface Order {
  order: {
    amount: number;
    create_time: string;
    order_no: string;
    payment_method: string;
    refund_time: string;
    shipping: number;
    status: string;
    refund_info: any[];
    transaction_id: string;
    user_id: number;
    discount_amount: number;
    is_coin_pay: boolean;
  };
  sub_orders: SubOrder[];
}

export interface PendingOrder {
  address_id: number;
  captcha?: {
    scene: string;
    session_id: string;
    sign: string;
    token: string;
  };
  from_cart: boolean;
  products: CartProduct[];
  address?: Address;
  user_coupon_id?: number;
  user_coupon_id_am_code?: number;
}

export interface OrdersState {
  list: Order[];
  query: ListQuery;
  count: number;
  pendingOrder: PendingOrder;
}

const state: OrdersState = {
  list: [],
  query: {
    ordering: "-create_time",
    page_number: 1,
    page_size: 10,
  },
  count: 0,
  pendingOrder: { user_coupon_id: 1 } as PendingOrder,
};

interface Context {
  commit: Commit;
  state: OrdersState;
  rootState: RootState;
}

export interface Payment {
  open_id: string;
  order_no: string;
  trade_type: string;
}

const actions = {
  async getList({ commit, state }: Context, query: ListQuery) {
    if (["paid", "shipping", "confirmed"].includes(String(query.status))) {
      query.sub_order_status = query.status;
      query.status = undefined;
    } else {
      query.sub_order_status = undefined;
      query.status = query.status || undefined;
    }
    const res: any = await request({
      url: url.order,
      params: query,
    });
    commit(
      "SET_LIST",
      query.page_number === 1 ? res.results : [...state.list, ...res.results]
    );
    commit("SET_QUERY", {
      ...query,
      page_number: res.page_number,
      page_size: res.page_size,
    });
    commit("SET_COUNT", res.count);
    return res;
  },
  get(context: Context, orderNo: string) {
    return request({
      url: url.order + "/" + orderNo,
    });
  },
  setPendingOrder({ commit }: Context, order: PendingOrder) {
    commit("SET_PENDING_ORDER", order);
  },
  post(context: Context, order: PendingOrder) {
    return request({
      method: "post",
      url: url.order,
      data: order,
    });
  },
  payment(context: Context, payment: Payment) {
    return request({
      method: "post",
      url: url.payment,
      data: payment,
    });
  },
  addressPost(
    context: Context,
    { orderNo, address }: { orderNo: string; address: Address }
  ) {
    return request({
      method: "post",
      url: url.order + "/" + orderNo + "/edit-addr",
      data: {
        address_info: address,
      },
    });
  },
  statusPatch(context: Context, order: Order) {
    return request({
      method: "patch",
      url: url.order + "/" + order.order.order_no + "/status",
      data: {
        status: order.order.status,
        refund_info: order.order.refund_info,
      },
    });
  },
  subStatusPatch(context: Context, subOrder: SubOrder) {
    return request({
      method: "patch",
      url: url.subOrder + "/" + subOrder.sub_order_no + "/status",
      data: {
        status: subOrder.status,
      },
    });
  },
};

const mutations = {
  SET_LIST: (state: OrdersState, list: Order[]) => {
    state.list = list;
  },
  SET_QUERY: (state: OrdersState, query: ListQuery) => {
    state.query = query;
  },
  SET_COUNT: (state: OrdersState, count: number) => {
    state.count = count;
  },
  SET_PENDING_ORDER: (state: OrdersState, pendingOrder: PendingOrder) => {
    state.pendingOrder = pendingOrder;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
