


















import Vue from "vue";

export default Vue.extend({
  name: "VList",
  props: {
    loading: Boolean,
    finished: Boolean,
  },
  data() {
    return {
      refreshLoading: false,
      listLoading: false,
    };
  },
  methods: {
    onRefresh() {
      if (this.loading) {
        return;
      }
      this.refreshLoading = true;
      this.listLoading = false;
      this.$emit("refresh");
    },
    onLoad() {
      if (this.loading || this.finished) {
        return;
      }
      this.refreshLoading = false;
      this.listLoading = true;
      this.$emit("load");
    },
  },
});
