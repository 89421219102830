























import Vue from "vue";

export default Vue.extend({
  name: "VMedia",
  props: {
    media: Object,
    imageOptions: Object,
    videoOptions: Object,
  },
  data() {
    return {};
  },
  computed: {
    imageQuery() {
      let {
        width,
        height,
        mode = "cover",
        type = "resize",
      } = this.imageOptions;
      const limit = width && height ? ",limit_0" : "";
      width = width ? ",w_" + Math.floor((width * 828) / 750) * 2 : "";
      height = height ? ",h_" + Math.floor((height * 828) / 750) * 2 : "";
      switch (mode) {
        case "cover":
          mode = ",m_fill";
          break;
        case "contain":
          mode = ",m_pad";
          break;
        case "fixed":
          mode = ",m_fixed";
          break;
      }
      return `?x-oss-process=image/${type}${width}${height}${mode}${limit}`;
    },
  },
});
