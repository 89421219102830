import Vue from "vue";
import {
  Area,
  Badge,
  Dialog,
  Field,
  Lazyload,
  List,
  Loading,
  Notify,
  Overlay,
  Popup,
  PullRefresh,
  Rate,
  Sticky,
  Switch,
  Swipe,
  SwipeItem,
  SwipeCell,
  Tabs,
  Tab,
} from "vant";
import "vant/lib/icon/local.css";
import "@/components/vant/index.scss";

Vue.use(Area);
Vue.use(Badge);
Vue.use(Dialog);
Vue.use(Field);
Vue.use(Lazyload);
Vue.use(List);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Popup);
Vue.use(PullRefresh);
Vue.use(Rate);
Vue.use(Sticky);
Vue.use(Switch);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(SwipeCell);
Vue.use(Tabs);
Vue.use(Tab);

Dialog.setDefaultOptions({
  confirmButtonColor: "#222222",
});

Notify.setDefaultOptions({
  color: "#ffffff",
  background: "#222222",
});
